import AddCircleIcon from "@mui/icons-material/AddCircle";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import DeleteIcon from "@mui/icons-material/Delete";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import SaveIcon from "@mui/icons-material/Save";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import { alpha, Box, Divider, Fab, FormGroup, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useHistory, useLocation } from "react-router-dom";
import logoLight from "../../assets/logo-pos.png";
import logo from "../../assets/logo.png";
import { environments } from "../../environments";
import {
  ConfigCaracterizacao,
  GenericObject,
  IErrorData,
  ImobiliarioEdificacao,
  ImobiliarioPessoa,
  Tabelas,
} from "../../interfaces";
import { DashboardContext } from "../../providers/Dashboard";
import { ColorModeContext } from "../../providers/Theme";
import {
  getData,
  getDataStructureByTable,
  getFirstIdLote,
  getForms,
  getPermissoesProjetos,
  getTenantConfig,
  sendLogs,
} from "../../services/api";
import {
  addEdificacaoToImobiliario,
  addPessoaToImobiliario,
  createImobiliario,
  createMensagem,
  createVisita,
  deleteImobiliario,
  isIdLoaded,
  patchTipoImpostoTo2,
  removeEdificacaoFromImobiliario,
  removeMensagem,
  removePessoaFromImobiliario,
  removeVisita,
  saveData,
  setLoadedId,
} from "../../services/data";
import { clearData, clearLogs, getAll, getOne, getStructure, saveStructure } from "../../services/db";
import pj from "./../../../package.json";
import { DialogPessoa } from "./DialogPessoa";
import { DialogVersion } from "./DialogVersion";
import Form from "./Form";
import FormSkeleton from "./FormSkeleton";
import Section from "./Section";
import SideDrawer from "./SideDrawer";
import SyncFeedback from "./SyncFeedback";
import TabNavigation from "./TabNavigation";
import WarningDialog from "./WarningDialog"
import { LeftBottomContainer } from "./styles";
import "./styles.css";
import { checkAutenticationByUrl } from "../../utils/checkAuthByUrl";
import { config } from "process";
import { ContentCut, JoinFull, MergeType } from "@mui/icons-material";
import { DialogSplitImobiliario } from "./DialogSplitImobiliario";
import { DialogJoin } from "./DialogJoin";


export default function Dashboard() {
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const color = useContext(ColorModeContext);

  const {
    metadata,
    setMetadata,
    errors,
    setShowDisabledImobiliario,
    showDisabledImobiliario,
    setFilterPessoaByImobiliario,
    filterPessoaByImobiliario,
    reloadFields,
    setReloadFields,
  } = useContext(DashboardContext);

  const [loading, setLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [openDialogPessoa, setOpenDialogPessoa] = useState(false);
  
  const [openDialogSplitImobiliario, setOpenDialogSplitImobiliario] = useState(false);
  const [openDialogJoin, setOpenDialogJoin] = useState(false);
  const municipio = localStorage.getItem("municipio").toLowerCase();
  /* states para o feedback da sincronização */
  const [showModal, setShowModal] = useState(false);
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [errorLog, setErrorLog] = useState([]);
  const [configCaracterizacao, setConfigCaracterizacao] = useState<ConfigCaracterizacao>();
  const [warningMessage, setWarningMessage] = useState<string>("");
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const [initialMetadata, setInitialMetadata] = useState(metadata);
  const [shouldPassInitialMetadata, setShouldPassInitialMetadata] = useState(false);
  const [imobiliarioStatus, setImobiliarioStatus] = useState({
    joinMaracanau: false,
    splitMaracanau: false,
  });

  const tables: Tabelas[] = [
    "lote",
    "imobiliario",
    "imobiliario_edificacao",
    "edificacao",
    "pessoa",
    "imobiliario_pessoa",
    "visitas",
    "mensagens",
    "midia",
  ];

  async function asyncFunctionInit() {
    setLoading(true);

    try {
      await checkAutenticationByUrl();
      const projectInfo = await getTenantConfig();
      setConfigCaracterizacao(projectInfo.configCaracterizacao);

      fetchData().then();
      fetchPermissions().then();
      fetchForms().then();
      getFirstIds();
    } catch (error) {
      console.error("Erro durante a inicialização:", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    asyncFunctionInit().then();
  }, [location]);

  async function fetchPermissions() {
    const permissions = await getPermissoesProjetos();
    if (metadata.permissoes || !permissions) return;

    setMetadata((current) => ({ ...current, permissoes: permissions }));
  }

  async function fetchForms() {
    const forms = await getForms();
    setMetadata((current) => ({ ...current, formularios: forms }));
  }

  function getFirstIds(table?: Tabelas) {
    if (table) {
      const data = getAll(table);
      if (data && data.length > 0) {
        if (table === "imobiliario") {
          const activeImobiliario = data.find((item: any) => item.status === 1 || item.status === "1");
          if (activeImobiliario) {
            setCurrentId(String(activeImobiliario.id), table);
          } else {
            setCurrentId(String(data[0].id), table);
          }
        } else {
          setCurrentId(String(data[0].id), table);
        }
      }
      return;
    }
  
    tables.forEach((table) => {
      const data = getAll(table);
      if (data && data.length > 0) {
        if (table === "imobiliario") {
          const activeImobiliario = data.find((item: any) => item.status === 1 || item.status === "1");
          if (activeImobiliario) {
            setCurrentId(String(activeImobiliario.id), table);
          } else {
            setCurrentId(String(data[0].id), table);
          }
        } else {
          setCurrentId(String(data[0].id), table);
        }
      }
    });
  } 

  async function fetchData() {
    let id_lote: number = 0;

    if (location.pathname.split("/")[2] && !isNaN(Number(location.pathname.split("/")[2]))) {
      id_lote = Number(location.pathname.split("/")[2]);
    } else {
      id_lote = await getFirstIdLote();
      history.push(`/dashboard/${id_lote}`);
    }

    if (isIdLoaded(String(id_lote))) {
      console.log("id já carregado");
      return;
    } else {
      clearData();
    }

    const data = await getData(id_lote);

    if (!data) {
      throw new Error("Não foi possível carregar os dados");
    }

    for (let table of tables) {
      const structure = await getDataStructureByTable(table);

      if (structure) {
        saveStructure(table, structure);
      }

      data[table].forEach((item) => saveData(item, table));
    }

    getFirstIds();
    setLoadedId(String(id_lote));
    setLoading(false);
  }

  function setCurrentId(id: string, tb: Tabelas) {
    setMetadata((current) => ({
      ...current,
      [`id_${tb}`]: String(id),
    }));
  }

  async function fetchAndUpdateDashboardData(id?: string, parameter?: string) {
    const id_lote = metadata.id_lote;
  
    try {
      const data = await getData(Number(id_lote));
  
      if (!data) {
        throw new Error("Não foi possível carregar os dados atualizados do servidor.");
      }
  
      const keysToRemove: string[] = [];
      Object.keys(localStorage).forEach((key) => {
        if (key.match(/^[0-9a-fA-F\-]{36}@[\w]+$/)) {
          keysToRemove.push(key);
        }
      });
  
      keysToRemove.forEach((key) => localStorage.removeItem(key));

      if (parameter === 'JoinMaracanau' && id) {
        const keysToRemoveFromStorage: string[] = [];
        Object.keys(localStorage).forEach((key) => {
          if (key.includes(`@imobiliario`) && key.includes(id)) {
            keysToRemoveFromStorage.push(key);
          }
        });
        keysToRemoveFromStorage.forEach((key) => localStorage.removeItem(key));
  
        const imobiliarios = getAll("imobiliario") || [];
        const updatedImobiliarios = imobiliarios.filter(
          (imovel: any) => imovel.id !== Number(id)
        );
        localStorage.setItem("list@imobiliario", JSON.stringify(updatedImobiliarios));
  
        Object.keys(metadata).forEach((key) => {
          if (metadata[key] === id) {
            metadata[key] = undefined;
          }
        });
      }
  
      Object.keys(localStorage).forEach((key) => {
        if (key.startsWith("list@")) {
          const rawValue = localStorage.getItem(key);
          if (rawValue) {
            try {
              const list = JSON.parse(rawValue);
              if (Array.isArray(list)) {
                const updatedList = list.filter((item) => {
                  if (typeof item.id === "string" && item.id.match(/^[0-9a-fA-F\-]{36}$/)) {
                    return false;
                  }
                  return true;
                });
  
                localStorage.setItem(key, JSON.stringify(updatedList));
              }
            } catch (error) {
              console.error(`Erro ao processar a lista no localStorage (${key}):`, error);
            }
          }
        }
      });
  
      Object.keys(data).forEach((table: string) => {
        if (Array.isArray(data[table])) {
          data[table] = data[table].filter((item: any) => {
            if (typeof item.id === "string" && item.id.match(/^[0-9a-fA-F\-]{36}$/)) {
              return false;
            }
            return true;
          });
  
          data[table].forEach((item: any) => {
            saveData(item, table as Tabelas);
          });
        }
      });
  
      Object.keys(metadata).forEach((key) => {
        if (typeof metadata[key] === "string" && metadata[key]?.match(/^[0-9a-fA-F\-]{36}$/)) {
          metadata[key] = undefined;
        }
      });
      fetchData();
      setIsDataUpdated(true);
      setInitialMetadata(metadata);
      setShouldPassInitialMetadata(true);
      if (parameter === 'JoinMaracanau' && id) {
        setImobiliarioStatus((prev) => ({
          ...prev,
          joinMaracanau: true,
        }));
        setShouldPassInitialMetadata(false);
      }
      if (parameter === "SplitMaracanau"){
        setImobiliarioStatus((prev) => ({
          ...prev,
          splitMaracanau: true,
        }));
        setShouldPassInitialMetadata(false);
      }
    } catch (error) {
      console.error("Erro ao atualizar dados do dashboard:", error);
      toast.error("Erro ao atualizar os dados do dashboard.");
    }
  }  

  function handleChangeImobiliario(event: React.SyntheticEvent, newValue: string) {
    setMetadata((current) => ({
      ...current,
      [`id_imobiliario`]: newValue,
    }));
  }

  function handleChangePessoa(event: React.SyntheticEvent, newValue: string) {
    const listImobiliarioPessoa = (getAll("imobiliario_pessoa") || []) as ImobiliarioPessoa[];

    const theImobiliarioPessoa = listImobiliarioPessoa.find(
      (item) => String(item.id_pessoa) === newValue && String(item.id_imobiliario) === metadata.id_imobiliario,
    );

    setMetadata((current) => ({
      ...current,
      id_imobiliario_pessoa:
        theImobiliarioPessoa && theImobiliarioPessoa.id ? String(theImobiliarioPessoa.id) : undefined,
      id_pessoa: newValue,
    }));
  }

  function handleChangeEdificacao(event: React.SyntheticEvent, newValue: string) {
    const listImobiliarioEdificacao = (getAll("imobiliario_edificacao") || []) as ImobiliarioEdificacao[];

    const theImobiliarioEdificacao = listImobiliarioEdificacao.find(
      (item) => String(item.id_edificacao) === newValue && String(item.id_imobiliario) === metadata.id_imobiliario,
    );

    setMetadata((current) => ({
      ...current,
      [`id_imobiliario_edificacao`]: theImobiliarioEdificacao?.id ? String(theImobiliarioEdificacao?.id) : undefined,
      [`id_edificacao`]: newValue,
    }));
  }

  function handleChangeVisitas(event: React.SyntheticEvent, newValue: string) {
    setMetadata((current) => ({
      ...current,
      [`id_visitas`]: newValue,
    }));
  }

  async function handleAddVisita() {
    let visita = await createVisita(metadata.id_imobiliario!);

    setCurrentId(visita.id, "visitas");
  }

  async function handleRemoveVisita() {
    removeVisita(metadata.id_visitas!);
    setMetadata((current) => ({
      ...current,
      id_visitas: undefined,
    }));
  }

  function handleChangeMensagens(event: React.SyntheticEvent, newValue: string) {
    setMetadata((current) => ({
      ...current,
      [`id_mensagens`]: newValue,
    }));
  }

  async function handleAddMensagem() {
    let mensagem = await createMensagem(metadata.id_imobiliario!, metadata.id_lote!);

    setCurrentId(mensagem.id, "mensagens");
  }

  async function handleRemoveMensagem() {
    removeMensagem(metadata.id_mensagens!);
    setMetadata((current) => ({
      ...current,
      id_mensagens: undefined,
    }));
  }

  async function handleAddImobiliario() {
    let imobiliario = await createImobiliario(metadata.id_lote!);

    setCurrentId(imobiliario.id, "imobiliario"); 
  }

  function handleRemoveImobiliario() {
    const listImobiliario = getAll("imobiliario");

    if (!checkIfTipoImpostoIsDisabled()) {
      handleTipoImposto();
    } else if (
      listImobiliario &&
      listImobiliario.filter((imobiliario: any) => imobiliario.status === 1 || imobiliario.status === "1").length > 1
    ) {
      const statusDelete = deleteImobiliario(metadata.id_imobiliario!);

      if (statusDelete) {
        toast.success("Sucesso ao deletar imobiliário ");

        setMetadata((current) => ({
          ...current,
          [`id_imobiliario`]: undefined,
        }));
      } else {
        toast.error("Falha ao deletar imobiliário ");
      }
    }
  }

  function handleAddPessoa() {
    setOpenDialogPessoa(true);
  }

  function handleDesvincularPessoa() {
    removePessoaFromImobiliario(metadata.id_imobiliario_pessoa!);
    setMetadata((current) => ({
      ...current,
      id_imobiliario_pessoa: undefined,
    }));
  }

  function handleDesvincularEdificacao() {
    removeEdificacaoFromImobiliario(metadata.id_imobiliario_edificacao!);
    setMetadata((current) => ({
      ...current,
      id_imobiliario_edificacao: undefined,
    }));
  }

  function handleVincularEdificacao() {
    const imobiliarioEdificacao = addEdificacaoToImobiliario(metadata.id_edificacao!, metadata.id_imobiliario!);

    setMetadata((current) => ({
      ...current,
      id_imobiliario_edificacao: imobiliarioEdificacao.id!,
    }));
  }

  function handleVincularPessoa() {
    const pessoa = getOne(metadata.id_pessoa!, "pessoa");
    const imobiliarioPessoa = addPessoaToImobiliario(pessoa!, metadata.id_imobiliario!);

    setMetadata((current) => ({
      ...current,
      id_imobiliario_pessoa: String(imobiliarioPessoa.id),
    }));
  }

  function handleSplitImobiliario(){
    setOpenDialogSplitImobiliario(true);
  }

  function handleJoinImobiliario(){
    setOpenDialogJoin(true);
  }

  function handleValidaVinculoPessoaMaracanau(): boolean {
    const allImobiliarios = getAll("imobiliario") || [];
    const listImobiliarioPessoa = getAll("imobiliario_pessoa") || [];
  
    const newImobiliarios = allImobiliarios.filter((imobiliario: any) => {
      const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
      return uuidRegex.test(imobiliario.id);
    });
  
    const newWithoutVinculoPessoa = newImobiliarios.filter((imobiliario: any) => {
      return !listImobiliarioPessoa.some(
        (link: any) => String(link.id_imobiliario) === String(imobiliario.id) &&
        (link.tipo === 1 || String(link.tipo) === "1")
      );
    });
  
    if (newWithoutVinculoPessoa.length > 0) {
      setWarningMessage(
        "Um ou mais imóveis que estão sendo criados não possuem proprietário vinculado. Certifique-se de vincular pelo menos um proprietário a cada novo imóvel antes de salvar."
      );
      setOpenWarningDialog(true);
      return false;
    }
    
    return true;
  }   
  
  async function handleSave() {
    switch (localStorage.getItem("municipio")) {
        case "maracanau":
            if (!handleValidaVinculoPessoaMaracanau()) {
                return;
            }

            const listImobiliarioPessoa = (getAll("imobiliario_pessoa") || []) as ImobiliarioPessoa[];
            const pessoasVinculadas = listImobiliarioPessoa.filter(
                (pessoa) =>
                    String(pessoa.id_imobiliario) === metadata.id_imobiliario &&
                    (pessoa.tipo === 1 || String(pessoa.tipo) === "1")
            );

            if (pessoasVinculadas.length === 0) {
                setWarningMessage("É necessário ter ao menos um proprietário vinculado ao imobiliário antes de salvar.");
                setOpenWarningDialog(true);
                return;
            }
            break;

        default:
            break;
    }

    setIsSaving(true);

    const hasLogs = localStorage.getItem("log") !== null;

    const toastId = toast.loading(
      hasLogs
        ? "Salvando as alterações. Por favor, aguarde enquanto os dados são processados e atualizados."
        : "Não há dados para salvar, mas os dados serão atualizados."
    );

    try {
        await sendLogs();
        clearLogs();
        
        await fetchAndUpdateDashboardData();

        toast.dismiss(toastId);
        toast.success(hasLogs
          ? "Dados salvos e atualizados com sucesso."
          : "Dados atualizados com sucesso.");
        setIsSaving(false);
    } catch (error: any) {
        console.error("Erro ao salvar os dados", error);

        if (error.response?.status === 422) {
            toast.dismiss(toastId);
            toast.error("Houve um erro com o formato dos dados, envie um chamado para o helpdesk");
        } else if (error.response?.status === 500) {
            toast.dismiss(toastId);
            toast.error("Ocorreu um erro desconhecido, por favor, envie um chamado para o helpdesk");
        } else {
            toast.dismiss(toastId);
            toast.error("Houve um erro ao salvar os dados");
            setErrorLog(error.response?.data?.errors || []);
            setShowModal(true);
        }

        setIsSaving(false);
    }
  }

  function checkIfImobiliarioIsDisabled() {
    const strutuctureImobiliario = getStructure("imobiliario");
    const dataSelectedImobiliario = getOne(metadata.id_imobiliario!, "imobiliario");

    return !(
      strutuctureImobiliario !== null &&
      strutuctureImobiliario.hasOwnProperty("status") &&
      (dataSelectedImobiliario?.status === 1 || dataSelectedImobiliario?.status === "1")
    );
  }

  function checkIfTipoImpostoIsDisabled() {
    const strutuctureLote = getStructure("lote");
    const strutuctureImobiliario = getStructure("imobiliario");
    const dataSelectedLote = getOne(metadata.id_lote!, "lote");
    const listImobiliario = getAll("imobiliario");

    return !(
      strutuctureLote !== null &&
      strutuctureImobiliario !== null &&
      strutuctureImobiliario.hasOwnProperty("status") &&
      listImobiliario &&
      listImobiliario.filter((imobiliario: any) => imobiliario.status === 1 || imobiliario.status === "1").length ===
        1 &&
      strutuctureLote.hasOwnProperty("tipo_imposto") &&
      (dataSelectedLote?.tipo_imposto === 1 || dataSelectedLote?.tipo_imposto === "1")
    );
  }

  function handleTipoImposto() {
    if (!checkIfTipoImpostoIsDisabled()) {
      const statusChangeTipoImposto = patchTipoImpostoTo2(metadata.id_lote!);

      if (statusChangeTipoImposto) {
        toast.success("Tipo imposto alterado para Territorial");
        setReloadFields(!reloadFields);
        return true;
      } else {
        toast.error("Falha ao trocar campo Tipo Imposto");
        return false;
      }
    }
  }

  function openAuditoriaCadastro(camada: string, idCamada: string, idOpcional?: string) {
    if (camada == "pessoa" || camada == "edificacao") {
      window.open(
        `/auditoria/cadastro?and=(or(camada.eq.${camada},camada.eq.imobiliario_${camada}),or(body_novo->>id.in.(${idCamada},${idOpcional}),body_antigo->>id.in.(${idCamada},${idOpcional})))`,
        "_blank",
      );
      return;
    }

    window.open(
      `/auditoria/cadastro?camada=eq.${camada}&or=(body_novo->>id.eq.${idCamada},body_antigo->>id.eq.${idCamada})`,
      "_blank",
    );
    return;
  }

  function handleFilterPessoaByImobiliario() {
    setFilterPessoaByImobiliario((current) => {
      const newFilterState = !current;
  
      if (newFilterState) {
        const pessoasVinculadas = (getAll("imobiliario_pessoa") || []).filter(
          (pessoa) => String(pessoa.id_imobiliario) === metadata.id_imobiliario
        );
  
        setMetadata((prev) => ({
          ...prev,
          id_pessoa: pessoasVinculadas.length > 0 ? String(pessoasVinculadas[0].id_pessoa) : undefined,
        }));
      } else {
        setMetadata((prev) => ({
          ...prev,
          id_pessoa: undefined,
        }));
      }
  
      return newFilterState;
    });
  }

  useEffect(() => {
    if (filterPessoaByImobiliario && metadata.id_imobiliario) {
      const pessoasVinculadas = (getAll("imobiliario_pessoa") || []).filter(
        (pessoa) => String(pessoa.id_imobiliario) === metadata.id_imobiliario
      );

      setMetadata((prev) => ({
        ...prev,
        id_pessoa: pessoasVinculadas.length > 0 ? String(pessoasVinculadas[0].id_pessoa) : undefined,
      }));
    }
  }, [filterPessoaByImobiliario, metadata.id_imobiliario]);

  const exigirPreenchimentoObrigatorio = configCaracterizacao?.naoExigirPreenchimentoObrigatorio === false;

  const shouldSaveButtonBeDisabled = isSaving || (exigirPreenchimentoObrigatorio && Object.keys(errors).length > 0);

  return (
    <>
      <Box
        sx={{
          width: metadata.drawer ? "68vw" : "95vw",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          id="logo-container"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            width: "100%",
            marginTop: theme.spacing(1),
            marginLeft: theme.spacing(2),
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
              }}
            >
              <img
                className="logo-dashboard"
                src={theme.palette.mode === "dark" ? logo : logoLight}
                alt="logo"
                onClick={() => console.log(metadata)}
              />
              <Divider
                sx={{
                  marginRight: theme.spacing(1),
                  marginLeft: theme.spacing(1),
                }}
                orientation="vertical"
                flexItem
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  variant="caption"
                  fontWeight={"700"}
                  fontStyle={{
                    color: alpha(theme.palette.text.secondary, 0.4),
                  }}
                >
                  Caracterização
                </Typography>

                <DialogVersion>
                  {pj.version} - {environments.ambiente}{" "}
                </DialogVersion>
              </Box>
            </Box>
            <Box id="dark-mode-toggle" sx={{ display: "flex", gap: theme.spacing(1) }}>
              <TabNavigation />
              <IconButton onClick={color.toggleColorMode} color="inherit">
                {theme.palette.mode === "dark" ? <Brightness7Icon /> : <DarkModeIcon />}
              </IconButton>
            </Box>
          </Box>
        </Box>

        <SideDrawer />

        <Box>
          {configCaracterizacao && "desabilitaLote" in configCaracterizacao && !configCaracterizacao.desabilitaLote && (
            <Section
              id="Lote"
              titulo="Lote"
              options={[
                <Tooltip key={"open-audit-lote"} title="Abrir auditoria do lote" sx={{ marginTop: 2 }}>
                  <IconButton
                    disabled={!metadata.id_lote}
                    onClick={() => metadata.id_lote && openAuditoriaCadastro("lote", metadata.id_lote)}
                  >
                    <ContentPasteSearchIcon />
                  </IconButton>
                </Tooltip>,
              ]}
              initialMetadata={shouldPassInitialMetadata ? initialMetadata : undefined}
              shouldPassInitialMetadata={shouldPassInitialMetadata} 
              setShouldPassInitialMetadata={setShouldPassInitialMetadata}
              imobiliarioStatus={imobiliarioStatus}
              setImobiliarioStatus={setImobiliarioStatus}
              isDataUpdated={isDataUpdated}
              setIsDataUpdated={setIsDataUpdated}
            >
              {metadata.formularios && !loading ? (
                <Form formularios={metadata.formularios.lote} tabela={"lote"} />
              ) : (
                <FormSkeleton />
              )}
            </Section>
          )}
          {configCaracterizacao &&
            "desabilitaImobiliario" in configCaracterizacao &&
            !configCaracterizacao.desabilitaImobiliario && (
              <Section
                id="Imobiliário"
                titulo="Imobiliário"
                tabela={"imobiliario"}
                handleChangeFn={handleChangeImobiliario}
                options={[
                  <Tooltip key={"add-imob"} title="Adicionar imobiliário">
                    <IconButton onClick={handleAddImobiliario} color="success">
                      <AddCircleIcon />
                    </IconButton>
                  </Tooltip>,
                  <Tooltip key={"del-imob"} title="Remover imobiliário" color="error">
                    <IconButton onClick={handleRemoveImobiliario} disabled={checkIfImobiliarioIsDisabled()}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>,
                  
                  ...(!!municipio && municipio === 'maracanau'
                    ? [
                        <Tooltip key="split-imob" title="Desmembrar imobiliário">
                          <IconButton onClick={handleSplitImobiliario} disabled={checkIfImobiliarioIsDisabled()}>
                            <ContentCut />
                          </IconButton>
                        </Tooltip>,
                        <Tooltip key="join-imob" title="Juntar imobiliários">
                          <IconButton onClick={handleJoinImobiliario} disabled={checkIfImobiliarioIsDisabled()}>
                            <JoinFull />
                          </IconButton>
                        </Tooltip>,
                      ]
                    : []),
                  <Tooltip key={"open-audit-imob"} title="Abrir auditoria do imobiliário">
                    <IconButton
                      disabled={!metadata.id_imobiliario}
                      onClick={() =>
                        metadata.id_imobiliario && openAuditoriaCadastro("imobiliario", metadata.id_imobiliario)
                      }
                    >
                      <ContentPasteSearchIcon />
                    </IconButton>
                  </Tooltip>,
                ]}
                extraOptions={[
                  <Tooltip key={"show-delete-imob"} title="Exibir imobiliários desabilitados">
                    <Box sx={{ display: "flex" }}>
                      <FormControlLabel
                        checked={showDisabledImobiliario}
                        onChange={(e: GenericObject) => {
                          setShowDisabledImobiliario(e.target.checked);
                        }}
                        control={<Checkbox color="default" />}
                        label="Exibir imobiliários desativados"
                      />
                    </Box>
                  </Tooltip>,
                ]}
                initialMetadata={shouldPassInitialMetadata ? initialMetadata : undefined}
                shouldPassInitialMetadata={shouldPassInitialMetadata} 
                setShouldPassInitialMetadata={setShouldPassInitialMetadata}
                imobiliarioStatus={imobiliarioStatus}
                setImobiliarioStatus={setImobiliarioStatus}
                isDataUpdated={isDataUpdated}
                setIsDataUpdated={setIsDataUpdated}
              >
                {metadata.formularios && !loading ? (
                  <Form formularios={metadata.formularios.imobiliario} tabela={"imobiliario"} />
                ) : (
                  <FormSkeleton />
                )}
              </Section>
            )}
          {configCaracterizacao &&
            "desabilitaPessoa" in configCaracterizacao &&
            !configCaracterizacao.desabilitaPessoa && (
              <Section
                id="Pessoa"
                titulo="Pessoa"
                tabela={"pessoa"}
                handleChangeFn={handleChangePessoa}
                options={[
                  <Tooltip key={"add-pessoa"} title="Adicionar uma nova pessoa ao imobiliário">
                    <IconButton
                      onClick={handleAddPessoa}
                      color="success"
                      disabled={metadata.id_imobiliario === undefined}
                    >
                      <AddCircleIcon />
                    </IconButton>
                  </Tooltip>,
                  <Tooltip key={"add-imob-pessoa"} title="Vincular pessoa ao imobiliário">
                    <IconButton onClick={handleVincularPessoa} disabled={metadata.id_imobiliario_pessoa !== undefined}>
                      <LinkIcon />
                    </IconButton>
                  </Tooltip>,
                  <Tooltip key={"del-imob-pessoa"} title="Desvincular pessoa do imobiliário">
                    <IconButton
                      onClick={handleDesvincularPessoa}
                      disabled={metadata.id_imobiliario_pessoa === undefined}
                    >
                      <LinkOffIcon />
                    </IconButton>
                  </Tooltip>,
                  <Tooltip key={"open-audit-pessoa"} title="Abrir auditoria da pessoa">
                    <IconButton
                      disabled={!metadata.id_pessoa}
                      onClick={() =>
                        metadata.id_pessoa &&
                        openAuditoriaCadastro("pessoa", metadata.id_pessoa, metadata.id_imobiliario_pessoa)
                      }
                    >
                      <ContentPasteSearchIcon />
                    </IconButton>
                  </Tooltip>,
                ]}
                extraOptions={[
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={filterPessoaByImobiliario}
                          onChange={handleFilterPessoaByImobiliario}
                        />
                      }
                      label={"Visualizar apenas pessoas do imobliário selecionado"}
                    />
                  </FormGroup>,
                ]}
                initialMetadata={shouldPassInitialMetadata ? initialMetadata : undefined}
                shouldPassInitialMetadata={shouldPassInitialMetadata} 
                setShouldPassInitialMetadata={setShouldPassInitialMetadata}
                imobiliarioStatus={imobiliarioStatus}
                setImobiliarioStatus={setImobiliarioStatus}
                isDataUpdated={isDataUpdated}
                setIsDataUpdated={setIsDataUpdated}
              >
                {!metadata.formularios && !loading && <FormSkeleton />}

                {metadata.formularios && metadata.id_imobiliario_pessoa && (
                  <Form formularios={metadata.formularios.imobiliario_pessoa} tabela={"imobiliario_pessoa"} />
                )}

                {metadata.formularios && <Form formularios={metadata.formularios.pessoa} tabela={"pessoa"} />}
              </Section>
            )}
          {configCaracterizacao &&
            "desabilitaEdificacao" in configCaracterizacao &&
            !configCaracterizacao.desabilitaEdificacao && (
              <Section
                id="Edificações"
                titulo="Edificacoes"
                tabela={"edificacao"}
                handleChangeFn={handleChangeEdificacao}
                options={[
                  <Tooltip key={"add-imob-edificacao"} title="Vincular edificação a unidade imobiliária selecionada">
                    <IconButton
                      onClick={handleVincularEdificacao}
                      disabled={metadata.id_imobiliario_edificacao !== undefined}
                    >
                      <LinkIcon />
                    </IconButton>
                  </Tooltip>,

                  <Tooltip
                    key={"del-imob-edificacao"}
                    title="Desvincular edificação de unidade imobiliária selecionada"
                  >
                    <IconButton
                      onClick={handleDesvincularEdificacao}
                      disabled={metadata.id_imobiliario_edificacao === undefined}
                    >
                      <LinkOffIcon />
                    </IconButton>
                  </Tooltip>,
                  <Tooltip key={"open-audit-edificacao"} title="Abrir auditoria da edificação">
                    <IconButton
                      disabled={!metadata.id_edificacao}
                      onClick={() =>
                        metadata.id_edificacao &&
                        openAuditoriaCadastro("edificacao", metadata.id_edificacao, metadata.id_imobiliario_edificacao)
                      }
                    >
                      <ContentPasteSearchIcon />
                    </IconButton>
                  </Tooltip>,
                ]}
                initialMetadata={shouldPassInitialMetadata ? initialMetadata : undefined}
                shouldPassInitialMetadata={shouldPassInitialMetadata} 
                setShouldPassInitialMetadata={setShouldPassInitialMetadata}
                imobiliarioStatus={imobiliarioStatus}
                setImobiliarioStatus={setImobiliarioStatus}
                isDataUpdated={isDataUpdated}
                setIsDataUpdated={setIsDataUpdated}
              >
                {metadata.formularios && !loading ? (
                  <Form formularios={metadata.formularios.imobiliario_edificacao} tabela={"imobiliario_edificacao"} />
                ) : (
                  <FormSkeleton />
                )}
                {metadata.formularios && !loading ? (
                  <Form formularios={metadata.formularios.edificacao} tabela={"edificacao"} />
                ) : (
                  <FormSkeleton />
                )}
              </Section>
            )}
          {configCaracterizacao &&
            "desabilitaMensagens" in configCaracterizacao &&
            !configCaracterizacao.desabilitaMensagens && (
              <Section
                id="Mensagens"
                titulo="Mensagens"
                tabela={"mensagens"}
                handleChangeFn={handleChangeMensagens}
                options={[
                  <Tooltip key={"add-msg"} title="Adicionar mensagem">
                    <IconButton onClick={handleAddMensagem} color="success">
                      <AddCircleIcon />
                    </IconButton>
                  </Tooltip>,
                  <Tooltip key={"del-msg"} title="Remove mensagem" color="error">
                    <IconButton onClick={handleRemoveMensagem} disabled={!metadata.id_mensagens}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>,
                  <Tooltip key={"open-audit-msg"} title="Abrir auditoria da mensagem">
                    <IconButton
                      disabled={!metadata.id_mensagens}
                      onClick={() => metadata.id_mensagens && openAuditoriaCadastro("mensagens", metadata.id_mensagens)}
                    >
                      <ContentPasteSearchIcon />
                    </IconButton>
                  </Tooltip>,
                ]}
                initialMetadata={shouldPassInitialMetadata ? initialMetadata : undefined}
                shouldPassInitialMetadata={shouldPassInitialMetadata} 
                setShouldPassInitialMetadata={setShouldPassInitialMetadata}
                imobiliarioStatus={imobiliarioStatus}
                setImobiliarioStatus={setImobiliarioStatus}
                isDataUpdated={isDataUpdated}
                setIsDataUpdated={setIsDataUpdated}
              >
                {metadata.formularios && !loading ? (
                  <Form formularios={metadata.formularios.mensagens} tabela={"mensagens"} />
                ) : (
                  <FormSkeleton />
                )}
              </Section>
            )}

          {configCaracterizacao &&
            "desabilitaVisita" in configCaracterizacao &&
            !configCaracterizacao.desabilitaVisita && (
              <Section
                id="Visitas & Controle"
                titulo="Visitas & Controle"
                tabela={"visitas"}
                handleChangeFn={handleChangeVisitas}
                options={[
                  <Tooltip key={"add-visita"} title="Adicionar visita">
                    <IconButton onClick={handleAddVisita} color="success">
                      <AddCircleIcon />
                    </IconButton>
                  </Tooltip>,
                  <Tooltip key={"del-vis"} title="Remove visita" color="error">
                    <IconButton onClick={handleRemoveVisita} disabled={!metadata.id_visitas}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>,
                  <Tooltip key={"open-audit-visita"} title="Abrir auditoria da visita">
                    <IconButton
                      disabled={!metadata.id_visitas}
                      onClick={() => metadata.id_visitas && openAuditoriaCadastro("mensagens", metadata.id_visitas)}
                    >
                      <ContentPasteSearchIcon />
                    </IconButton>
                  </Tooltip>,
                ]}
                initialMetadata={shouldPassInitialMetadata ? initialMetadata : undefined}
                shouldPassInitialMetadata={shouldPassInitialMetadata} 
                setShouldPassInitialMetadata={setShouldPassInitialMetadata}
                imobiliarioStatus={imobiliarioStatus}
                setImobiliarioStatus={setImobiliarioStatus}
                isDataUpdated={isDataUpdated}
                setIsDataUpdated={setIsDataUpdated}
              >
                {metadata.formularios && !loading ? (
                  <Form formularios={metadata.formularios.visitas} tabela={"visitas"} />
                ) : (
                  <FormSkeleton />
                )}
              </Section>
            )}
        </Box>
      </Box>

      <LeftBottomContainer drawer={metadata.drawer}>
        {
          <Fab
            sx={{ marginRight: theme.spacing(1) }}
            variant="extended"
            onClick={handleSave}
            color="success"
            disabled={shouldSaveButtonBeDisabled}
          >
            <SaveIcon />
            {!metadata.drawer && <>{!isSaving ? <>&nbsp; Salvar alterações</> : <>&nbsp; Salvando alterações</>}</>}
          </Fab>
        }
        <Fab
          variant="extended"
          onClick={() =>
            setMetadata((current) => ({
              ...current,
              drawer: !current.drawer,
            }))
          }
          color="primary"
        >
          {metadata.drawer ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </Fab>
      </LeftBottomContainer>

      <DialogSplitImobiliario openDialog={openDialogSplitImobiliario} handleCloseDialog={() => setOpenDialogSplitImobiliario(false)} fetchAndUpdateDashboardData={fetchAndUpdateDashboardData} />
      <DialogJoin openDialog={openDialogJoin} handleCloseDialog={() => setOpenDialogJoin(false)} fetchAndUpdateDashboardData={fetchAndUpdateDashboardData} />
      <DialogPessoa openDialog={openDialogPessoa} handleCloseDialog={() => setOpenDialogPessoa(false)} />
      <WarningDialog
        open={openWarningDialog}
        onClose={() => setOpenWarningDialog(false)}
        title="Aviso"
        message={warningMessage} 
      />

      {showModal && <SyncFeedback errorLog={errorLog} showModal={showModal} setShowModal={setShowModal} />}
    </>
  );
}

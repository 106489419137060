import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { AnyData, IMetadata, ProjetosCamadasFormulariosCampo, Tabelas } from "../../../interfaces";
import { DashboardContext } from "../../../providers/Dashboard";
import { isCurrentColorModeDark } from "../../../providers/Theme";
import { getAll, getOne } from "../../../services/db";
import SearchField from "../SearchField";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import "./styles.css";

export default function Section(props: {
  titulo: string;
  tabela?: Tabelas;
  handleChangeFn?: (event: React.SyntheticEvent, newValue: string) => void;
  children?: React.ReactNode;
  options?: React.ReactNode[];
  extraOptions?: React.ReactNode[];
  id: string;
  isDataUpdated: boolean;
  setIsDataUpdated: React.Dispatch<React.SetStateAction<boolean>>;
  initialMetadata: IMetadata;
  shouldPassInitialMetadata: boolean;
  setShouldPassInitialMetadata: React.Dispatch<React.SetStateAction<boolean>>;
  imobiliarioStatus: {
    joinMaracanau: boolean;
    splitMaracanau: boolean;
  };
  setImobiliarioStatus: React.Dispatch<
    React.SetStateAction<{
      joinMaracanau: boolean;
      splitMaracanau: boolean;
    }>
  >;
}) {
  const { metadata, setMetadata, showDisabledImobiliario, filterPessoaByImobiliario } = useContext(DashboardContext);
  const [resumo, setResumo] = useState<ProjetosCamadasFormulariosCampo[]>();
  const [search, setSearch] = useState<string>("");
  const [list, setList] = useState<AnyData[] | undefined>();
  const [listOfImobiliario, setListOfImobiliario] = useState<{
    imobiliario: AnyData | undefined;
    imobiliario_pessoa: AnyData[] | undefined;
    imobiliario_edificacao: AnyData[] | undefined;
  }>();
  const [openAccordion, setOpenAccordion] = useState<boolean>(true);
  const filteredList =
    list &&
    list?.filter(shouldShowBySearch).filter(shouldShowThisImobiliario).filter(shouldShowThisPessoa).sort(orderList);
  const theme = useTheme();

  useEffect(() => {
    if (metadata.id_imobiliario) {
      setListOfImobiliario({
        imobiliario: getOne(metadata.id_imobiliario, "imobiliario")!,
        imobiliario_pessoa: getAll("imobiliario_pessoa")?.filter(
          (item) => item.id_imobiliario == metadata.id_imobiliario,
        ),
        imobiliario_edificacao: getAll("imobiliario_edificacao")?.filter(
          (item) => item.id_imobiliario == metadata.id_imobiliario,
        ),
      });
    }
  }, [metadata]);

  useEffect(() => {
    if (props.isDataUpdated) {
      if (list && list.length > 0) {
        const updatedList = list.filter((item) => {
          const storageKey = `${item.id}@${props.tabela}`;
          return localStorage.getItem(storageKey) !== null;
        });

        setList(updatedList);
      }

      props.setIsDataUpdated(false);
    }
  }, [props.isDataUpdated]);

  useEffect(() => {
    if (props.tabela) {
      setList(getAll(props.tabela));
    }

    if (metadata.formularios && props.tabela) {
      let resumos = metadata.formularios[props.tabela]
        .find((form) => form.representacao === "caracterizacao")
        ?.projetosCamadasFormulariosCampos.filter((item) => item.resumo);

      if (resumos) {
        setResumo(resumos);
      }
    }
  }, [metadata]);

  useEffect(() => {
    if (!metadata[`id_${props.tabela as Tabelas}`]) {
      if (filteredList && filteredList.length > 0) {
        setMetadata((current) => ({
          ...current,
          [`id_${props.tabela as Tabelas}`]: String(filteredList[0].id),
        }));
      } else {
        setMetadata((current) => ({
          ...current,
          [`id_${props.tabela as Tabelas}`]: undefined,
        }));
      }
    }
  }, [filteredList, props.tabela, setMetadata]);

  useEffect(() => {
    if (props.imobiliarioStatus.joinMaracanau) {
      setMetadata((current) => {
        return { ...current };
      });
  
      props.setImobiliarioStatus((prev) => ({
        ...prev,
        joinMaracanau: false,
      }));
    }

    if (props.imobiliarioStatus.splitMaracanau && props.tabela === "imobiliario") {
      const updatedList = getAll("imobiliario");
      setList(updatedList);

      if (updatedList.length > 0) {
        const lastItem = updatedList[updatedList.length - 1];

        setMetadata((current) => ({
          ...current,
          id_imobiliario: String(lastItem.id),
        }));

        const vinculoPessoa = (getAll("imobiliario_pessoa") || []).find(
          (pessoa) => String(pessoa.id_imobiliario) === String(lastItem.id)
        );
  
        if (vinculoPessoa) {
          setMetadata((current) => ({
            ...current,
            id_pessoa: String(vinculoPessoa.id_pessoa),
            id_imobiliario_pessoa: String(vinculoPessoa.id),
          }));
        }
      }

      props.setImobiliarioStatus((prev) => ({
        ...prev,
        splitMaracanau: false,
      }));
    }
  }, [props.imobiliarioStatus])

  useEffect(() => {
    if (props.shouldPassInitialMetadata) {
      setMetadata((current) => {
        const updatedMetadata = { ...current };
  
        Object.keys(props.initialMetadata || {}).forEach((key) => {
          const table = key.replace(/^id_/, "") as Tabelas;
          const list = getAll(table);
  
          if (props.initialMetadata[key] === undefined && list && list.length > 0) {
            updatedMetadata[key] = String(list[list.length - 1].id);
          } 
          else {
            updatedMetadata[key] = props.initialMetadata[key];
          }
        });
        
        return updatedMetadata;
      });
  
      props.setShouldPassInitialMetadata(false);
    }
  }, [props.shouldPassInitialMetadata]);  

  function shouldShowThisImobiliario(tab: AnyData) {
    if (props.tabela === "imobiliario" && tab.status) {
      return showDisabledImobiliario || tab.status === 1 || tab.status === "1";
    }
    return true;
  }

  function shouldShowThisPessoa(tab: AnyData) {
    if (props.tabela !== "pessoa" || !filterPessoaByImobiliario) return true;

    if (props.tabela === "pessoa" && listOfImobiliario && listOfImobiliario.imobiliario_pessoa) {
      return listOfImobiliario.imobiliario_pessoa?.map((e) => String(e.id_pessoa)).includes(String(tab.id));
    }
  }

  function shouldShowBySearch(item: AnyData) {
    let resumos = resumo ? resumo.map((resumo) => String(item[resumo.tblNomeColuna])) : [];

    resumos.push(String(item.id));

    if (resumos.some((resumo) => resumo.toLowerCase().includes(search.toLowerCase()))) {
      return true;
    }

    return false;
  }

  function orderList(a: AnyData, b: AnyData) {
    if (
      props.tabela !== "edificacao" &&
      props.tabela !== "pessoa" &&
      props.tabela !== "visitas" &&
      props.tabela !== "mensagens"
    )
      return 0;

    if (!listOfImobiliario) return 0;

    if (props.tabela === "edificacao") {
      // console.log(
      //   `Será que o ${a.id} está na lista ${listOfImobiliario.imobiliario_edificacao?.map(e => String(e.id_edificacao))}? ${listOfImobiliario.imobiliario_edificacao?.map(e => String(e.id_edificacao)).includes(String(a.id))}`, listOfImobiliario.imobiliario_edificacao?.map(e => String(e.id_edificacao)).includes(String(a.id)) ? 1 : -1
      // )

      if (
        listOfImobiliario.imobiliario_edificacao?.map((e) => String(e.id_edificacao)).includes(String(a.id)) &&
        listOfImobiliario.imobiliario_edificacao?.map((e) => String(e.id_edificacao)).includes(String(b.id))
      )
        return 0;

      return listOfImobiliario.imobiliario_edificacao?.map((e) => String(e.id_edificacao)).includes(String(a.id))
        ? -1
        : 1;
    }

    if (props.tabela === "pessoa") {
      // console.log(
      //   `Será que o ${a.id} está na lista ${listOfImobiliario.imobiliario_pessoa?.map(e => String(e.id_pessoa))}? ${listOfImobiliario.imobiliario_pessoa?.map(e => String(e.id_pessoa)).includes(String(a.id))}`, listOfImobiliario.imobiliario_pessoa?.map(e => String(e.id_pessoa)).includes(String(a.id)) ? 1 : -1
      // )

      if (
        listOfImobiliario.imobiliario_pessoa?.map((e) => String(e.id_pessoa)).includes(String(a.id)) &&
        listOfImobiliario.imobiliario_pessoa?.map((e) => String(e.id_pessoa)).includes(String(b.id))
      )
        return 0;

      return listOfImobiliario.imobiliario_pessoa?.map((e) => String(e.id_pessoa)).includes(String(a.id)) ? -1 : 1;
    }

    if (props.tabela === "mensagens") {
      if (
        listOfImobiliario.imobiliario?.id == a?.id_imobiliario &&
        listOfImobiliario.imobiliario?.id == b?.id_imobiliario
      )
        return 0;

      return listOfImobiliario.imobiliario?.id == a?.id_imobiliario ? -1 : 1;
    }

    if (props.tabela === "visitas") {
      if (
        listOfImobiliario.imobiliario?.id == a?.id_imobiliario &&
        listOfImobiliario.imobiliario?.id == b?.id_imobiliario
      )
        return 0;

      return listOfImobiliario.imobiliario?.id == a?.id_imobiliario ? -1 : 1;
    }

    return 0;
  }

  function highlightTabItem(id: string, item?: AnyData) {
    if (
      props.tabela !== "edificacao" &&
      props.tabela !== "pessoa" &&
      props.tabela !== "visitas" &&
      props.tabela !== "mensagens"
    )
      return false;

    if (
      props.tabela === "visitas" &&
      listOfImobiliario &&
      listOfImobiliario.imobiliario &&
      item &&
      item?.id_imobiliario
    )
      return listOfImobiliario.imobiliario.id == item?.id_imobiliario;

    if (
      props.tabela === "mensagens" &&
      listOfImobiliario &&
      listOfImobiliario.imobiliario &&
      item &&
      item?.id_imobiliario
    )
      return listOfImobiliario.imobiliario.id == item?.id_imobiliario;

    if (props.tabela === "edificacao" && listOfImobiliario && listOfImobiliario.imobiliario_edificacao)
      return listOfImobiliario.imobiliario_edificacao?.map((e) => String(e.id_edificacao)).includes(String(id));

    if (props.tabela === "pessoa" && listOfImobiliario && listOfImobiliario.imobiliario_pessoa)
      return listOfImobiliario.imobiliario_pessoa?.map((e) => String(e.id_pessoa)).includes(String(id));
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openExtraOptions = Boolean(anchorEl);
  const handleOpenExtraOptions = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseExtraOptions = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      id={props.id}
      sx={{
        padding: "1rem",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={8} onClick={() => setOpenAccordion(!openAccordion)}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <IconButton className={openAccordion ? "arrowOpen" : "arrowClose"}>
              <ExpandMoreIcon />
            </IconButton>
            <h2>{props.titulo}</h2>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {props.tabela && <SearchField value={search} setValue={setSearch} />}
            {props.options && props.options.map((item) => item)}
            {props.extraOptions && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={openExtraOptions ? "long-menu" : undefined}
                  aria-expanded={openExtraOptions ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleOpenExtraOptions}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={anchorEl}
                  open={openExtraOptions}
                  onClose={handleCloseExtraOptions}
                >
                  {props.extraOptions.map((option, i) => (
                    <MenuItem key={i}>{option}</MenuItem>
                  ))}
                </Menu>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>

      <Divider />

      {props.tabela && (
        <>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              variant="scrollable"
              value={metadata[`id_${props.tabela as Tabelas}`] as string}
              onChange={props.handleChangeFn}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#009c66",
                },
              }}
            >
              {props.tabela &&
                filteredList &&
                filteredList.map((item, index) => {
                  return (
                    <Tab
                      sx={{
                        color: "primary.light",
                        backgroundColor: highlightTabItem(item.id, item)
                          ? alpha(theme.palette.secondary.light, 0.1)
                          : "none",
                        width: "25%",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        maxWidth: "25%",
                        "&.Mui-selected": isCurrentColorModeDark()
                          ? {
                              color: "primary.contrastText",
                            }
                          : {},
                        "&:hover": isCurrentColorModeDark()
                          ? {
                              color: "primary.contrastText",
                              opacity: 1,
                            }
                          : {},
                      }}
                      key={index}
                      label={
                        <>
                          <Tooltip title={"ID: " + item.id}>
                            <Typography>{item.id}</Typography>
                          </Tooltip>
                          {resumo &&
                            resumo.map((resumo, index) => {
                              return (
                                <Tooltip key={index} title={`${resumo.nome}: ${item[resumo.tblNomeColuna]}`}>
                                  <Typography variant="caption">{item[resumo.tblNomeColuna]}</Typography>
                                </Tooltip>
                              );
                            })}
                        </>
                      }
                      value={String(item.id)}
                    />
                  );
                })}
            </Tabs>
          </Box>
        </>
      )}
      <Box className={` accordion ${openAccordion ? "accordionOpen" : "accordionClose"}`}>
        {((list && list.length > 0) || !props.tabela) && props.children}
      </Box>
    </Box>
  );
}

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Imobiliario } from "../../../interfaces";
import { DashboardContext } from "../../../providers/Dashboard";
import { getAll } from "../../../services/db";
import { unirImoveis } from "../../../services/api";
import toast from "react-hot-toast";

interface Props {
  openDialog: boolean;
  handleCloseDialog: () => void;
  fetchAndUpdateDashboardData: (id?: string, parameter?: string) => Promise<void>;
}

export function DialogJoin({handleCloseDialog, openDialog, fetchAndUpdateDashboardData} : Props) {
  const { metadata, setMetadata, setReloadFields } = useContext(DashboardContext);
  const [imoveis, setImoveis] = useState<Imobiliario[]>([]);
  const [selectedImovel, setSelectedImovel] = useState<Imobiliario | null>(null);

  useEffect(() => {
    setImoveis(
      getAll("imobiliario")?.filter((imovel: Imobiliario) => imovel.id !== Number(metadata?.id_imobiliario)) as Imobiliario[]
    );
  }, [metadata]);

  function handleSelectImovel(imovel: Imobiliario) {
    setSelectedImovel((prev) => (prev?.id === imovel.id ? null : imovel)); 
  }

  async function handleJoin() {
    if (selectedImovel) {
      try {
        const resultado = await unirImoveis(metadata.id_imobiliario, Number(selectedImovel.id));
        if (resultado === "success") {

          setSelectedImovel(null);
          handleCloseDialog();

          await fetchAndUpdateDashboardData(String(selectedImovel.id), 'JoinMaracanau');

          setMetadata((current) => {
            return { ...current };
          });

          toast.success("União realizada com sucesso!");
        } else {
          toast.error("Falha na união.");
        }
      } catch (error) {
        console.error("Erro ao realizar a união:", error);
        toast.error("Ocorreu um erro ao tentar unir os imóveis.");
      }
    } else {
      console.log("Nenhum imóvel selecionado.");
    }
  }  

  return (
    <Dialog open={openDialog} onClose={handleCloseDialog}>
      <DialogContent>
        <h2 style={{ textAlign: "center" }}>Selecione uma unidade imobiliária para unir ao imóvel</h2>

        <List component="nav">
          {imoveis?.map((imovel: Imobiliario) => (
            <ListItemButton
              key={imovel.id}
              sx={{
                borderBottom: "1px solid #5f5f5f",
                backgroundColor: selectedImovel?.id === imovel.id ? "#4b4b4b" : "inherit", 
              }}
              onClick={() => handleSelectImovel(imovel)}
            >
              <ListItemText
                primary={imovel.inscricao_cartografica}
                secondary={`Número da unidade imobiliária: ${imovel.nr_unidade} | Número de Cadastro: ${
                  imovel.numero_cadastro || "Não informado"
                }`}
              />
            </ListItemButton>
          ))}
        </List>
      </DialogContent>

      <DialogActions
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          color="success"
          onClick={handleJoin}
          variant="contained"
          disabled={!selectedImovel}
        >
          Juntar imóveis
        </Button>
      </DialogActions>
    </Dialog>
  );
}
